const DEV_MODE = false;

const config = {
	APP_VERSION: '1.8.6',
	SERVER_ROOT: DEV_MODE ? 'https://test.loginopedia.it/' : 'https://app2.loginopedia.it/',
	SERVER_BASENAME: '/app/',
	API_BASENAME: DEV_MODE ? 'https://test.loginopedia.it/api' : 'https://app2.loginopedia.it/api',
	TIMEOUT_DELAY: 10
};

export default config